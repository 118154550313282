/* eslint-disable object-curly-spacing */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const GbiBridged = (): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { backgroundImageCareer } = useStaticQuery(
    graphql`
      query {
        backgroundImageCareer: file(relativePath: {eq: "karriere-bei-triceonic-img2.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              width: 2000 
              quality: 50
              webpOptions: {quality: 70}
            )
          }
        }
      }
    `
  );

  const image = getImage(backgroundImageCareer as ImageDataLike);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      className="masthead"
      preserveStackingContext
    />
  );
};

export default GbiBridged;
