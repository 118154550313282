import * as React from 'react';
import '../styles/grid.css';
import '../styles/styles.css';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BgImage from '../components/HeaderCareer';

export default function career (): React.ReactElement {

  return (
    <Layout>
      <div id='career'>
        <div className='imageWrapper clipPathBottom'>
          <BgImage />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>Karriere bei Triceonic</h1>
              <p>Du suchst nach einer Stelle die vielseitig ist und Freiraum bietet? Wir haben die passende Stelle für dich. Starte mit uns durch und werde Teil unseres Teams!<br />
                Wir denken Strategieumsetzung neu. Mit unserem Software-Produkt ermöglichen wir erstmals, Beziehungen zwischen strategischen Zielen und operativen Aufgaben vollständig abzubilden und aktuell zu halten. Wir lieben komplexe Herausforderungen und arbeiten mit Triceonic konsequent daran, ein hocheffizientes und gleichzeitig motivierendes Arbeitsumfeld für unsere Kunden und deren Mitarbeiter zu schaffen. Wir stehen für Qualität und eigenverantwortliches Handeln und freuen uns auf Verstärkung bei dieser Mission.
              </p>
            </div>
          </div>
        </div>
        <div className='eyeCatcher clipPathBottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-9'>
                <p>Kein Job für dich dabei? Du kannst dich jederzeit initiativ bei uns bewerben. Schicke uns einfach deinen Lebenslauf und ein paar Infos </p>
              </div>
              <div className='col-3'>
                <a href='tel:+437203712391750'>
                  <button className='lightButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faPhone} />
                    Jetzt anrufen!
                  </button>
                </a>
                <a href='mailto:career@triceonic.com'>
                  <button className='lightButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                    Jetzt bewerben!
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <a href='#firstJob' className='careerLinks'>
              <div className='jobs'>
                <h3>Vertriebsspezialist/in für Software as a Service Produkt (m/w/d)</h3>
                <p>Linz / Vollzeit</p>
              </div>
            </a>
            <a href='#secondJob' className='careerLinks'>
              <div className='jobs'>
                <h3>Senior Software Engineer (m/w/d)</h3>
                <p>Linz / Vollzeit</p>
              </div>
            </a>
          </div>
        </div>
        <div className='backgroundColorDarker' id='firstJob'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2>Vertriebsspezialist/in für Software as a Service Produkt (m/w/d)</h2>
                <a href='mailto:career@triceonic.com'>
                  <button className='darkButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                    Jetzt bewerben!
                  </button>
                </a>
                <h3>Deine Aufgaben</h3>
                <ul>
                  <li>Sei der Ansprechpartner für unsere Kunden und Interessenten</li>
                  <li>Identifiziere und gewinne neue Kunden für unsere Produkte</li>
                  <li>Gestalte und lebe ein aktives Beziehungsmanagement mit Kunden und Partnern</li>
                  <li>Entwickle das Vertriebskonzept weiter, baue das Kundenportfolio aus und analysiere potenzielle Absatzmärkte</li>
                  <li>Erstelle attraktive Angebote und führe Vertrags- und Preisverhandlungen</li>
                  <li>Repräsentiere aktiv das Unternehmen bei Events und Kundenveranstaltungen, etc.</li>
                </ul>
                <h3>Dein Profil</h3>
                <p>Du suchst nach einer Stelle die vielseitig ist und deine Fähigkeiten auslotet? Wir haben die passende Stelle für dich. <br />
                  Mit folgenden Eigenschaften bist du dabei:</p>
                <h4>Fachliches Profil</h4>
                <ul>
                  <li>Erfahrung im Vertrieb von Softwarelösungen idealerweise SaaS Produkte</li>
                  <li>Herausragende Präsentationsfähigkeiten und Verhandlungsgeschick</li>
                  <li>Fundierte Kenntnisse über gängige IT-Systemlandschaften</li>
                  <li>Offenheit für agile Arbeitsweisen</li>
                  <li>Prozess Know-how im Bereich Strategieumsetzung von Vorteil</li>
                  <li>Verhandlungssichere Deutsch- und Englischkenntnisse</li>
                </ul>
                <h4>Persönliches Profil</h4>
                <ul>
                  <li>Gutes Auftreten und ausgesprochen gute Kommunikationsfähigkeiten</li>
                  <li>Freude an komplexen Aufgabenstellungen und dem Überführen in effiziente Lösungen</li>
                  <li>Proaktives, eigenverantwortliches und lösungsorientiert Arbeiten</li>
                  <li> Kontaktfreudige und durchsetzungsstarke Persönlichkeit</li>
                  <li>Affinität für neue Herausforderungen sowie für fachliche und persönliche Weiterentwicklung</li>
                  <li>Bereitschaft für Dienstreisen</li>
                </ul>
                <h3>Das bieten wir</h3>
                <ul>
                  <li>Hoher Gestaltungsfreiraum und die Möglichkeit, einen Geschäftsbereich weiter auf- und auszubauen</li>
                  <li>Einen Weg zu deiner Work-Life Balance (Arbeitszeiteinteilung, Hybridoffice etc.)</li>
                  <li>Flexible Arbeitszeiten ohne All-In Vertrag</li>
                  <li>Eine Startup-Umgebung mit allem, was dazu gehört</li>
                  <li>Dynamisches Umfeld mit flachen Hierarchien</li>
                  <li>Die Möglichkeit mit der Fima zu wachsen</li>
                  <li>Triceonic nutzt als Innovation- and Communication Hub den factory300 Campus in der Tabakfabrik Linz</li>
                  <li>Triceonic Credits: Für deine individuelle Wohlfühlumgebung am Arbeitsplatz (spezielles IT-Equipment, ergonomischer Bürosessel etc.)</li>
                </ul>
                <h3>Entgelt und Benefits</h3>
                <p>Gehaltsspanne: <br />
                  €3.500,00 bis maximal €4.000,00 (14 x p.a. für 38,5h Woche) abhängig von deiner Vorerfahrung und Qualifikation. <br />
                  <ul>
                    <li>Dienstort: Hybrid / Linz, Tabakfabrik</li>
                    <li>Dienstantritt: 01.10.2023</li>
                  </ul>
                  Wenn wir dein Interesse geweckt haben, sende deine Bewerbung bitte an <a href='mailto:career@triceonic.com'>career@triceonic.com</a>. <br />
                  Wenn du dir nicht sicher bist, bewirb dich trotzdem.
                  Wir freuen uns auf dich!</p>
                <h3>Dein Ansprechpartner</h3>
                Christoph Tanzer<br />
                COO & Co-Founder<br />
                <a href='tel:+437203712391750'>+43 720 37 1239 – 1750</a><br />
                <a href='mailto:career@triceonic.com'>career@triceonic.com</a>
              </div>
            </div>
          </div>
        </div>
        <div id='secondJob'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2>Senior Software Engineer (m/w/d)</h2>
                <a href='mailto:career@triceonic.com'>
                  <button className='darkButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                    Jetzt bewerben!
                  </button>
                </a>
                <h3>Deine Aufgaben</h3>
                <ul>
                  <li>Entwickle eigenständig Produktfunktionen und betreue diese über den gesamten Life-Cycle hinweg</li>
                  <li>Plane im kooperativen Team die Systemarchitektur von Grund auf</li>
                  <li>Stelle die fachliche Kompetenz in der jeweiligen Technik sicher</li>
                  <li>Gestalte Entwicklungsprozesse für ein schlagkräftiges Team mit</li>
                  <li>Unterstütze unsere Kunden und Services mit deinen eignen Ideen</li>
                </ul>
                <h3>Dein Profil</h3>
                <p>Mit folgenden Eigenschaften bist du dabei:</p>
                <h4>Fachliches Profil</h4>
                <ul>
                  <li>Fundierte Erfahrung in der Software-Entwicklung</li>
                  <li>Vertraut mit und begeistert von React, Amazon Web Services, JavaScript, TypeScript, Python, u.ä.</li>
                  <li>Offenheit für agile Arbeitsweisen</li>
                  <li>Prozess Know-How im Bereich Strategieumsetzung von Vorteil</li>
                  <li>Verhandlungssichere Deutsch- und Englischkenntnisse</li>
                </ul>
                <h4>Persönliches Profil</h4>
                <ul>
                  <li>Freude an komplexen Aufgabenstellungen</li>
                  <li>Proaktives, analytisches und ganzheitliches Denken</li>
                  <li>Leidenschaft für das Überführen von Kundenanforderungen in effiziente Lösungen</li>
                  <li>Affinität für neue Herausforderungen sowie für fachliche und persönliche Weiterentwicklung</li>
                  <li>Kommunikative, durchsetzungsstarke und kooperative Persönlichkeit</li>
                  <li>Bereitschaft für vereinzelte Dienstreisen</li>
                </ul>
                <h3>Das bieten wir</h3>
                <ul>
                  <li>Einen Weg zu deiner Work-Life Balance (Arbeitszeiteinteilung, Home Office Hybrid Office etc.)</li>
                  <li>Flexible Arbeitszeiten ohne All-In Vertrag</li>
                  <li>Eine Startup-Umgebung mit all dem was dazu gehört</li>
                  <li>Die Möglichkeit mit der Fima zu wachsen</li>
                  <li>Green-Field Entwicklung</li>
                  <li>Triceonic nutzt als Innovation- and Communication Hub den factory300 Campus in der Tabakfabrik Linz</li>
                  <li>Triceonic Credits: Für deine individuelle Wohlfühlumgebung am Arbeitsplatz (spezielles IT Equipment, ergonomischer Bürosessel etc.)</li>
                </ul>
                <h3>Entgelt und Benefits</h3>
                <p>Gehaltsspanne: <br />
                  €3.800,00 bis maximal €4.400,00 (14 x p.a. für 38,5h Woche) abhängig von deiner Vorerfahrung und Qualifikation. <br />
                  <ul>
                    <li>Dienstort: Linz, Tabakfabrik</li>
                    <li>Dienstantritt: 01.01.2024</li>
                  </ul>
                  Wenn wir dein Interesse geweckt haben, sende deine Bewerbung bitte an <a href='mailto:career@triceonic.com'>career@triceonic.com</a>. <br />
                  Wenn du dir nicht sicher bist, bewirb dich trotzdem.
                  Wir freuen uns auf dich!</p>
                <h3>Dein Ansprechpartner</h3>
                Christoph Tanzer<br />
                COO & Co-Founder<br />
                <a href='tel:+437203712391750'>+43 720 37 1239 – 1750</a><br />
                <a href='mailto:career@triceonic.com'>career@triceonic.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}
